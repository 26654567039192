import React from 'react'
import { useAdminContext } from '../../context/admin-context'

import './week-matrix.css';
import RentalPropertyBookings from '../rental-property-bookings/rental-property-bookings';

export default function WeekMatrix() {
  const {
    propertiesData,
  } = useAdminContext();

  const { properties, isLoading } = propertiesData;

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="week_matrix">
      {properties.map(({ id }) => {
        return (
          <RentalPropertyBookings
            propertyId={id}
            key={id}
          />
        )
      })}
    </div>
  )
}
