import { useAdminContext } from "../context/admin-context";

const useActiveBooking = () => {
  const {
    activeWeekNumber,
    activeYear,
    activeBookingId,
    activePropertyId,
    reservationsData,
    propertiesData
  } = useAdminContext();

  if (!activeBookingId) {
    return {
      isActive: false,
    };
  }

  const rentalProperty = propertiesData.properties.find((property) => property.id === activePropertyId);
  const propertyReservations = reservationsData.reservations[activePropertyId];
  const reservation = propertyReservations.find(({ id }) => id === activeBookingId);

  return {
    isActive: true,
    activeWeekNumber,
    activeBookingId,
    activePropertyId,
    activeYear,
    rentalProperty,
    reservation
  };
}

export default useActiveBooking;