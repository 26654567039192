import React, { useMemo, useState } from "react"
import axios from 'axios'
import useSignIn from 'react-auth-kit/hooks/useSignIn';

import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@mui/material'
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';

import { API_URL } from "../../config";

import './login.css'

const SignInComponent = () => {
  const signIn = useSignIn()
  const [formData, setFormData] = useState({ username: '', password: '' })

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const isFormValid = useMemo(() => (
    formData.username && formData.username.length > 4 && formData.password && formData.password.length > 4
  ), [formData]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.username || !formData.password) {
      console.log('Please enter username and password');
      return
    }

    try {
      const response = await axios.post(`${API_URL}/auth/login`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
      })
      if (response.status === 200) {
        (signIn({
          auth: {
            token: response.data.token,
            type: 'Bearer'
          },
          userState: {
            username: formData.username
          }
          // refresh: response.data.refreshToken,
        }))

        console.log('Sign in successful');
        window.location.replace('/')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="app__sign_in">
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <TextField
          required
          id="outlined-basic"
          label="Användarnamn"
          variant="outlined"
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
        />
        {/* <TextField
          required
          id="outlined-basic"
          label="Lösenord"
          variant="outlined"
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
        /> */}

        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Lösenord</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            label="Lösenord"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="visa dölj lösenord"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button
          variant="contained"
          type="submit"
          disabled={!isFormValid}
        >
          Logga in
        </Button>
      </Box>
    </div>
  )
}

export default SignInComponent