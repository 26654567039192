import { useAdminContext } from "../context/admin-context";
import { getAvailableWeeks } from "../utils/get-available-weeks";

const useAvailableWeeks = () => {
  const {
    reservationsData,
    propertiesData,
    activeWeekNumber,
    activePropertyId,
    activeYear
  } = useAdminContext();

  const propertyReservations = reservationsData.reservations[activePropertyId] || [];

  const availableWeeks = getAvailableWeeks({
    reservations: propertyReservations,
    weekNumber: activeWeekNumber,
    year: activeYear,
    maxWeeks: 4
  });

  const availableWeeksArr = [];

  for (let i = 0; i < availableWeeks; i++) {
    availableWeeksArr.push(i + 1,);
  }

  console.log('useAvailableWeeks', availableWeeksArr, reservationsData, activeWeekNumber, activePropertyId);

  return availableWeeksArr;
}

export default useAvailableWeeks;