import React from 'react'
import { useAdminContext } from '../../context/admin-context'

import './rental-properties-list.css'

export default function RentalPropertiesList() {
  const { propertiesData } = useAdminContext();

  const { properties, isLoading } = propertiesData;

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="rental_properties">
      {properties.map(({ id, name }, index) => (
        <div key={id} className="rental_properties__property">
          {name}
        </div>
      ))}
    </div>
  )
}
