import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useAdminContext } from '../../context/admin-context'
import FormSectionAdditions from './form-section-additions'
import { API_URL } from "../../config";
import {
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  // Tab
} from '@mui/material';
// import {
//   TabContext,
//   TabList,
//   TabPanel
// } from '@mui/lab';
import {
  getSelectedDateFromSelectedWeek,
  getFutureDateFromFutureWeek
} from '../../utils/date-utils';
import clx from '../../utils/clx'
import { format, set } from 'date-fns';
import { sv } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
// import TabPanel from './tab-panel.jsx'

import './booking-form.css'

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

const defaultFormData = {
  additionsPrice: 0,
  bookingId: '',
  guestCountry: '',
  guestFirstName: '',
  guestLastName: '',
  guestStreet: '',
  guestPostalNumber: '',
  guestPostalAddress: '',
  guestEmail: '',
  guestPhone: '',
  rentalPropertyId: '',
  startWeek: '',
  description: '',
  startDate: '',
  endDate: '',
  originalPrice: '',
  customPrice: '',
  startYear: '',
}

const fetchPropertyPrice = async (propertyId, startWeek, startYear, numberOfWeeks) => {
  const response = await fetch(`${API_URL}/properties/${propertyId}/available?startWeek=${startWeek}&endWeek=${startWeek + numberOfWeeks - 1}&year=${startYear}&minBeds=1`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  });
  if (!response.ok) {
    if (response.status === 404) {
      return { total_price: 0 };
    }

    throw new Error('Failed to fetch property price');
  }
  return response.json();
}


/**
 * @typedef {Object} BookingFormProps
 * @property {number[]} [numberOfWeekOptions] - Array of available week options.
 * @property {(formData: FormData) => void} [onSubmit] - Function to handle form submission.
 * @property {FormData} [preloadedFormData] - Preloaded form data.
 */

/**
 * Component for booking form.
 * @param {BookingFormProps} props - Props for the BookingForm component.
 * @returns {JSX.Element} - React component.
 */
export default function BookingForm({
  numberOfWeekOptions = [1, 2, 3, 4],
  preloadedFormData
}) {
  // const [activeTab, setActiveTab] = useState("1");
  const { enqueueSnackbar } = useSnackbar();

  const [isValidForSubmit, setIsValidForSubmit] = useState(false);

  const [isModified, setIsModified] = useState(false);

  const [formData, setFormData] = useState({
    ...defaultFormData,
    ...preloadedFormData
  });

  // Memoized callback to set the booking possibility
  const checkIfPossibleToBook = useCallback(
    () => {
      const isPossible = Boolean(
        (formData.customPrice || formData.originalPrice) &&
        formData.guestFirstName &&
        formData.guestLastName &&
        formData.guestEmail &&
        formData.guestPhone
      );
      console.log('IsPossibleToBook?', isPossible);
      setIsValidForSubmit(isPossible);
    },
    [formData]
  );

  const debouncedCheckIfPossibleToBook = useMemo(
    () => debounce(checkIfPossibleToBook, 600), // Adjust the debounce delay as needed
    [checkIfPossibleToBook]
  );

  // Effect to run the debounced check whenever formData changes
  useEffect(() => {
    debouncedCheckIfPossibleToBook();
  }, [debouncedCheckIfPossibleToBook]);

  const {
    activeBookingId,
    activeYear,
    activeWeekNumber,
    setActiveState,
    setActiveNumberOfWeeks,
    setActiveBookingId,
    activeNumberOfWeeks,
    reservationsData
  } = useAdminContext();

  const {
    addReservation,
    updateReservation
  } = reservationsData;

  const { data, error, refetch: refetchPropertyPrice, isLoading } = useQuery(
    ['propertyPrice', formData.rentalPropertyId, formData.startWeek, formData.startYear, activeNumberOfWeeks],
    () => fetchPropertyPrice(formData.rentalPropertyId, formData.startWeek, formData.startYear, activeNumberOfWeeks),
    {
      enabled: !activeBookingId && !!formData.rentalPropertyId && !!formData.startWeek && !!formData.startYear,
      cacheTime: 300000,
      refetchOnWindowFocus: false
    }
  );

  const calculatedPrice = useMemo(() => {
    let total = 0;
    if (data && data.total_price) {
      total += parseInt(data.total_price, 10);
    }
    
    // if (formData.additionsPrice) {
    //   total += parseInt(formData.additionsPrice);
    // }
    
    return total;

  }, [data]);

  const onSubmit = async (formData) => {
    if (formData.bookingId) {
      // Update booking
      const { error, data } = await updateReservation(formData);
      if (error) {
        console.error('Error updating booking', error);
        enqueueSnackbar('Fel uppstod vid uppdatering av bokning', { variant: 'error' })

        return;
      }

      enqueueSnackbar('Bokning uppdaterad', { variant: 'success' })
    } else {
      // Create booking
      console.log('Create booking', formData);
      const bookingData = {
        ...formData,
        endWeek: formData.startWeek + activeNumberOfWeeks - 1,
        endYear: formData.startYear,
      }
      const { error, data } = await addReservation(bookingData);

      if (error) {
        console.error('Error adding reservation', error);
        enqueueSnackbar('Fel uppstod vid skapande av bokning', { variant: 'error' })
        return;
      }

      setFormData((currentFormData) => ({
        ...currentFormData,
        bookingId: data.id
      }));
      setActiveBookingId(data.id);
      enqueueSnackbar(`Bokning (${data.id}) skapad`, { variant: 'success' })
    }
  }

  useEffect(() => {
    console.log('calculatedPrice changed', calculatedPrice)
    if (calculatedPrice) {
      setFormData((currentFormData) => ({
        ...currentFormData,
        originalPrice: calculatedPrice,
        // customPrice: currentFormData.customPrice || calculatedPrice
      }));
    }
  }, [calculatedPrice])

  // const handleTabChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  // TODO: create validator for when enough data is set to create a booking

  // const isPossibleToBook = useMemo(() => {
  //   const isPossible = Boolean((formData.customPrice || formData.originalPrice) && formData.guestFirstName && formData.guestLastName && formData.guestEmail && formData.guestPhone);
  //   console.log('IsPossibleToBook?', isPossible);
  //   return isPossible;
  // }, [formData.customPrice, formData.guestEmail, formData.guestFirstName, formData.guestLastName, formData.guestPhone, formData.originalPrice])

  useEffect(() => {
    // Check if formData is different from preloadedFormData
    const comparableFields = [
      'guestFirstName',
      'guestLastName',
      'guestStreet',
      'guestPostalNumber',
      'guestPostalAddress',
      'guestEmail',
      'guestPhone',
      'guestCountry',
      'customPrice',
      'numberOfWeeks',
      'description'
    ];

    const originalFormData = {
      ...defaultFormData,
      ...preloadedFormData
    };

    let isModified = false;

    for (let field of comparableFields) {
      if (field === 'customPrice') {
        // console.log('customPrice', formData.customPrice, originalFormData.customPrice, formData.customPrice !== originalFormData.customPrice)
        if (!originalFormData.customPrice && formData.customPrice !== formData.originalPrice) {
          isModified = true;
          break;
        }
        if (originalFormData.customPrice && originalFormData.customPrice !== formData.customPrice) {
          isModified = true;
          break;
        }
      } else if (formData[field] !== originalFormData[field]) {
        isModified = true;
        break;
      }
    }

    // const isFormDataModified = JSON.stringify(formData) !== JSON.stringify({
    //   ...defaultFormData,
    //   ...preloadedFormData
    // });

    setIsModified(isModified);
  }, [formData, preloadedFormData]);

  // set start and end date when startWeek and startYear are set
  useEffect(() => {
    if (formData.startWeek && formData.startYear) {
      const startDate = getSelectedDateFromSelectedWeek(formData.startYear, formData.startWeek);
      const endDate = getFutureDateFromFutureWeek(formData.startYear, formData.startWeek, formData.startWeek + activeNumberOfWeeks - 1);
      setFormData((currentFormData) => ({
        ...currentFormData,
        startDate,
        endDate
      }));
    }
  }, [formData.startWeek, formData.startYear, activeNumberOfWeeks])

  useEffect(() => {
    setFormData((currentFormData) => ({
      ...defaultFormData,
      ...currentFormData,
      ...preloadedFormData
    }));
  }, [preloadedFormData])

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  }

  const resetForm = (event) => {
    setFormData({
      ...defaultFormData,
      ...preloadedFormData,
      startDate: getSelectedDateFromSelectedWeek(activeYear, activeWeekNumber),
      endDate: getFutureDateFromFutureWeek(activeYear, activeWeekNumber, activeWeekNumber + activeNumberOfWeeks - 1)
    });
    setActiveNumberOfWeeks(1);
    if (!formData.bookingId) {
      refetchPropertyPrice().then((refetchResponse) => {
        const { data } = refetchResponse;
        if (data.total_price) {
          setFormData((currentFormData) => ({
            ...currentFormData,
            originalPrice: data.total_price,
            customPrice: data.total_price
          }));
        }
      });
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (name === 'numberOfWeeks') {
      setActiveState({
        activeNumberOfWeeks: value
      })
    }
  }

  const setAdditionsPriceFn = React.useCallback((additionsPrice) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      additionsPrice: currentFormData.originalPrice + additionsPrice
    }));
  }, [setFormData])

  return (
    <div className={clx('booking_form', {
      'booking_form--modified': isModified
    })}>
      <div className="booking_form__buttons">
        <Button
          className="booking_form__button"
          disabled={!isModified || !isValidForSubmit}
          onClick={handleSubmit}
          variant="contained"
        >
          {formData.bookingId ? 'Uppdatera bokning' : 'Skapa bokning'}
        </Button>
        <Button
          className="booking_form__button"
          disabled={!isModified}
          onClick={resetForm}
          variant="outlined"
        >
          Återställ
        </Button>
      </div>

      <div className="booking_form__messages">
        {/* {isLoading && <div className="booking_form__message">Laddar pris...</div>} */}
        {error && <div className="booking_form__message booking_form__message--error">Ett fel uppstod vid hämtning av pris</div>}
        {!formData.bookingId && !isLoading && !error && !calculatedPrice && <div className="booking_form__message">Pris kan ej beräknas. Bokningsperiod kanske ej möjlig</div>}
      </div>

      {/* TODO: Use tabs in mobile view? */}

      {/* <Box
        className="booking_form__box"
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1,
            width: '90%'
          },
        }}
        autoComplete='off'
      > */}

      <Box sx={{
        width: '100%',
        // typography: 'body1' 
      }}>
        {/* <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Gäst" value="1" />
              <Tab label="Bokning" value="2" />
              <Tab label="Överblick" value="3" />
            </TabList>
          </Box> */}

        {/* <TabPanel value="1"> */}

        <h3>Bokningsdetaljer</h3>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '1rem',
          marginTop: '1rem'
        }}>
          {formData.bookingId && (
            <TextField
              name="bookingId"
              disabled
              label="Bokningsnummer"
              value={formData.bookingId}
              size="small"
              sx={{
                maxWidth: '8rem',
              }}
            />
          )}
          <TextField
            name="rentalPropertyId"
            disabled
            id="outlined-required"
            label="Lägenhet"
            value={formData.rentalPropertyId}
            size="small"
            sx={{
              maxWidth: '5rem',
            }} />
          <TextField
            disabled
            onChange={handleChange}
            name="startWeek"
            id="outlined-required"
            label="Startvecka"
            value={formData.startWeek}
            size="small"
            sx={{
              maxWidth: '6rem',
            }} />
          <FormControl
            size="small"
          >
            <InputLabel id="demo-simple-select-label">Antal veckor</InputLabel>
            <Select
              name="numberOfWeeks"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={formData.bookingId ? true : false}
              // TODO: Fix better handling for updating booking number of weeks
              value={formData.bookingId ? formData.numberOfWeeks : activeNumberOfWeeks}
              label="Antal veckor"
              onChange={handleChange}
            >
              {numberOfWeekOptions.map((option) => (
                <MenuItem key={option} value={option}>{`${option} veck${option > 1 ? 'or' : 'a'}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <h3>Gäst</h3>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '1rem',
          marginTop: '1rem'
        }}>
          <TextField
            required
            id="outlined-required"
            name="guestFirstName"
            onChange={handleChange}
            label="Förnamn"
            value={formData.guestFirstName}
            size="small"
            sx={{
              maxWidth: '12rem',
            }}
          />
          <TextField
            name="guestLastName"
            id="outlined-required"
            label="Efternamn"
            onChange={handleChange}
            value={formData.guestLastName}
            size="small"
            sx={{
              width: '18rem'
            }}
          />
          <TextField
            id="outlined-required"
            name="guestStreet"
            onChange={handleChange}
            label="Adress"
            value={formData.guestStreet}
            size="small"
            sx={{
              width: '18rem'
            }}
          />
          <TextField
            id="outlined-required"
            name="guestPostalNumber"
            label="Postnummer"
            onChange={handleChange}
            value={formData.guestPostalNumber}
            size="small"
            sx={{
              width: '8rem'
            }}
          />
          <TextField
            id="outlined-required"
            name="guestPostalAddress"
            label="Ort"
            onChange={handleChange}
            value={formData.guestPostalAddress}
            size="small"
            sx={{
              width: '10rem'
            }}
          />
          <TextField
            name="guestEmail"
            id="outlined-required"
            label="E-post"
            onChange={handleChange}
            value={formData.guestEmail}
            size="small"
            sx={{
              width: '18rem'
            }}
          />
          <TextField
            name="guestPhone"
            // id="outlined-required"
            label="Telefon"
            onChange={handleChange}
            value={formData.guestPhone}
            size="small"
          />
          <TextField
            name="guestCountry"
            // id="outlined-required"
            label="Land"
            onChange={handleChange}
            value={formData.guestCountry}
            size="small"
          />
        </Box>

        <h3>Tillägg</h3>
        <FormSectionAdditions onAdditionsTotalCostChange={setAdditionsPriceFn} />

        {/* </TabPanel> */}

        {/* <TabPanel value="2"> */}

        {/* </TabPanel> */}

        {/* <TabPanel value="3"> */}
        <h3>Summering</h3>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '1rem',
          marginTop: '1rem'
        }}>
          <TextField
            disabled
            name="startDate"
            label="Ankomstdag"
            onChange={handleChange}
            size="small"
            value={formData.startDate ? format(formData.startDate, 'EEE d MMM yyyy', { locale: sv }) : ''}
          />
          <TextField
            disabled
            name="endDate"
            onChange={handleChange}
            label="Avresedag"
            size="small"
            value={formData.endDate ? format(formData.endDate, 'EEE d MMM yyyy', { locale: sv }) : ''}
          />

          <TextField
            disabled
            name="originalPrice"
            id="outlined-required"
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            label="Beräknat pris"
            value={formData.originalPrice || '?'}
            size="small"
            sx={{
              width: '12rem',
            }}
          />
          <TextField
            name="customPrice"
            id="outlined-required"
            onChange={handleChange}
            label="Alternativt pris"
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            size="small"
            value={formData.customPrice}
          />
        </Box>
        {/* </TabPanel> */}
        {/* </TabContext> */}
      </Box>

      {/* </Box> */}
    </div>
  )
}

BookingForm.propTypes = {
  numberOfWeekOptions: PropTypes.arrayOf(PropTypes.number),
  onNumberOfWeeksChange: PropTypes.func,
  onSubmit: PropTypes.func,
  preloadedFormData: PropTypes.shape({
    bookingId: PropTypes.number,
    customPrice: PropTypes.number,
    description: PropTypes.string,
    endDate: PropTypes.string,
    guestEmail: PropTypes.string,
    guestFirstName: PropTypes.string,
    guestLastName: PropTypes.string,
    guestPhone: PropTypes.string,
    guestPostalAddress: PropTypes.string,
    guestPostalNumber: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    guestStreet: PropTypes.string,
    numberOfWeeks: PropTypes.number,
    originalPrice: PropTypes.number,
    rentalPropertyName: PropTypes.string,
    rentalPropertyId: PropTypes.number,
    startDate: PropTypes.string,
    startWeek: PropTypes.number,
    startYear: PropTypes.number
  })
};