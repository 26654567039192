import React, { useState, useMemo, useEffect } from 'react'
import {
  Container,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  TextField,
  Grid
} from '@mui/material';

export default function FormSectionAdditions({
  onAdditionsTotalCostChange = () => { }
}) {

  const [endCleaning, setEndCleaning] = useState(false);
  const [cancellationInsurance, setCancellationInsurance] = useState(false);
  const [bedLinens, setBedLinens] = useState(0);

  const endCleaningPrice = 900;
  const cancellationInsurancePrice = 1500;
  const bedLinensPrice = 200;

  const handleEndCleaningChange = (event) => {
    setEndCleaning(event.target.checked);
  };

  const handleCancellationInsuranceChange = (event) => {
    setCancellationInsurance(event.target.checked);
  };

  const handleBedLinensChange = (event) => {
    const value = event.target.value;
    setBedLinens(value >= 0 && value <= 4 ? value : bedLinens);
  };

  const calculateTotal = useMemo(() => {
    let total = 0;
    if (endCleaning) total += endCleaningPrice;
    if (cancellationInsurance) total += cancellationInsurancePrice;
    total += bedLinens * bedLinensPrice;
    return total;
  }, [bedLinens, cancellationInsurance, endCleaning])

  useEffect(() => {
    onAdditionsTotalCostChange(calculateTotal);
  }, [calculateTotal, onAdditionsTotalCostChange])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
          paddingLeft: '10px',
          gap: '6px',
          paddingBlock: '10px'
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={endCleaning}
              onChange={handleEndCleaningChange}
              size="small"
            />
          }
          label={`Slutstädning (${endCleaningPrice} kr)`}
        />
        <FormControlLabel
          control={
            <Switch
              checked={cancellationInsurance}
              onChange={handleCancellationInsuranceChange}
              size="small"
            />
          }
          label={`Avbeställningsskydd (${cancellationInsurancePrice} kr)`}
        />
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography>Sängkläder och handdukar ({bedLinensPrice} kr / person):</Typography>
        </Grid>
        <Grid item>
          <TextField
            type="number"
            inputProps={{ min: 0, max: 4 }}
            value={bedLinens}
            onChange={handleBedLinensChange}
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
      <Typography>
        Kostnad för tillägg: {calculateTotal} kr
      </Typography>
    </Box>
  );
}

