import DetailPanel from '../../components/detail-panel/detail-panel';
import MainHeader from '../../components/main-header/main-header';
import RentalPropertiesList from '../../components/rental-properties-list/rental-properties-list';
import WeekMatrix from '../../components/week-matrix/week-matrix';
// import { withSignIn } from 'react-auth-kit'
// import { useAdminContext } from './context/admin-context';
import {
  createBrowserRouter,
  Route,
  Link
} from "react-router-dom";

import './dashboard.css';

const DashBoard = () => (
  <>
    <MainHeader />
    <div className="app__main">
      <RentalPropertiesList />
      <WeekMatrix />
    </div>
    <DetailPanel />
  </>
)

export default DashBoard;