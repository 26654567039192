import React from 'react'
import { useAdminContext } from '../../context/admin-context'
import DetailedBookingView from '../detailed-booking-view/detailed-booking-view'
import DetailedWeekView from '../detailed-week-view/detailed-week-view'

import './detail-panel.css'

export default function DetailPanel() {
  const { activeBookingId, activeWeekNumber } = useAdminContext();

  return (
    <div className="detail_panel">
      {activeBookingId && (
        <DetailedBookingView />
      )}
      {!activeBookingId && activeWeekNumber && (
        <DetailedWeekView />
      )}
    </div>
  )
}
