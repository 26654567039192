import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AdminProvider } from './context/admin-context.jsx';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';

import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import RequireAuth from '@auth-kit/react-router/RequireAuth';

import { RouterProvider } from 'react-router-dom';
import DashBoard from './routes/dashboard/dashboard.jsx';
import Login from './routes/login/login.jsx';
import { createBrowserRouter } from "react-router-dom";

const store = createStore({
  authName:'_auth_admin_ringaren',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

const queryClient = new QueryClient();


const router = createBrowserRouter([
  {
    path: '/',
    element: <RequireAuth fallbackPath='/login'><DashBoard /></RequireAuth>,
  },
  {
    path: '/login',
    element: <Login />,
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider store={store} >
      <SnackbarProvider maxSnack={3}>
        <QueryClientProvider client={queryClient}>
          <AdminProvider>
            <div className="app">
            <RouterProvider router={router} />
            </div>
          </AdminProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
