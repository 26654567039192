import React from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import { useAdminContext } from '../../context/admin-context'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from 'react-router-dom';

import './main-header.css'

export default function MainHeader() {
  const { activeBookingId, activeWeekNumber, activeYear, activePropertyId, setActiveYear, availableYears } = useAdminContext();
  const authUser = useAuthUser();
  const signOut = useSignOut()
  const navigate = useNavigate();

  const handleYearChange = (event) => {
    setActiveYear(event.target.value);
  }

  const yearOptions = availableYears.map(year => (
    <MenuItem key={year} value={year}>{year}</MenuItem>
  ));

  return (
    <div className="main-header">

      <div className="main-header__year">
        <Box sx={{ minWidth: 100 }}>
          <FormControl fullWidth>
            <InputLabel id="year-select-label">År</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={activeYear}
              label="År"
              onChange={handleYearChange}
              size="small"
            >
              {yearOptions}
            </Select>
          </FormControl>
        </Box>
      </div>

      <div className="main-header__title">
        Ringarens Hotell Administration
      </div>

      <div className="main-header__user">
        <span className="main-header__user__username">{authUser.username}</span>
        <Button
          size='small'
          variant="outlined"
          onClick={() => {
            signOut();
            navigate('/login')
          }}
        >
          Logga ut
        </Button>
      </div>
      {/* <div className="main-header__selections">
        <div className="main-header__selections__property">
          <span>Bokning:</span>
          <span>{activeBookingId}</span>
        </div>
        <div className="main-header__selections__week">
          <span>Vecka:</span>
          <span>{activeWeekNumber}</span>
          <span>Lägenhet:</span>
          <span>{activePropertyId}</span>
        </div>
      </div> */}
    </div>
  )
}
