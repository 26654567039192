import React from 'react'
import useActiveBooking from '../../hooks/use-active-booking';
// import useAvailableWeeks from '../../hooks/use-available-weeks';

import BookingForm from '../booking-form/booking-form';
import './detailed-booking-view.css'

export default function DetailedBookingView() {
  const activeBooking = useActiveBooking();
  // const availableWeeks = useAvailableWeeks();
  const {
    activeYear,
    activeWeekNumber,
    activePropertyId,
    rentalProperty,
    reservation = {}
  } = activeBooking;

  console.log('DetailedBookingView > activeBooking', activeBooking, reservation)

  const { id: bookingId, ...reservationRest} = reservation;


  const preloadedFormData = {
    startYear: activeYear,
    endYear: activeYear,
    bookingId,
    ...reservationRest,
    rentalPropertyName: rentalProperty?.name,
    numberOfWeeks: reservation.endWeek - reservation.startWeek + 1,
  }

  const availableWeeks = Array.from({ length: preloadedFormData.numberOfWeeks }, (_, i) => i + 1);

  return (
    <div className="detailed_booking_view">
      <div className="detailed_booking_view__header">
        <div className="detailed_booking_view__week_number">
          {`Vecka ${activeWeekNumber}`}
        </div>
        <div className="detailed_week_view__property_id">
          {`Lägenhet ${activePropertyId}`}
        </div>
      </div>
      <div className="detailed_week_view__create_booking">
        <BookingForm
          numberOfWeekOptions={availableWeeks}
          preloadedFormData={preloadedFormData}
          onSubmit={(formData) => {
            console.log('Form submitted', formData);
          }}
        />
      </div>
    </div>
  )
}
