import { useAdminContext } from "../context/admin-context";

const useActiveWeek = () => {
  const { 
    activeWeekNumber,
    activeYear,
    activePropertyId,
    propertiesData,
    // reservationsData
  } = useAdminContext();
  if (!activeWeekNumber) {
    return {
      isActive: false,
    };
  }

  const property = propertiesData.properties.find((property) => property.id === activePropertyId);

  return {
    isActive: true,
    activeYear,
    activeWeekNumber,
    activePropertyId,
    property
  };
}

export default useActiveWeek;