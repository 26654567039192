import React, { createContext, useContext, useMemo, useReducer } from 'react'
import {
  useQuery,
  useMutation
} from 'react-query'
import { normalizeReservationData } from '../utils/normalize-reservation-data'
import { fillMissingYears } from '../utils/date-utils';
import { API_URL } from '../config';

const fetchYearsWithBookings = async () => {
  const response = await fetch(`${API_URL}/bookings/years`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  })
  if (!response.ok) {
    throw new Error('Failed to fetch years with bookings')
  }

  return response.json()
}

const fetchRentalProperties = async () => {
  const response = await fetch(`${API_URL}/properties`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  })
  if (!response.ok) {
    throw new Error('Failed to fetch rental properties')
  }

  return response.json()
}

const fetchReservations = async () => {
  const response = await fetch(`${API_URL}/bookings`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  })
  if (!response.ok) {
    throw new Error('Failed to fetch bookings')
  }

  return response.json()
}

const createReservation = async reservation => {
  const response = await fetch(`${API_URL}/bookings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    body: JSON.stringify(reservation),
    credentials: 'include',
  })

  if (!response.ok) {
    throw new Error('Failed to create reservation')
  }

  return response.json()
}

const updateReservation = async reservation => {
  const response = await fetch(`${API_URL}/bookings/${reservation.bookingId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    body: JSON.stringify(reservation),
    credentials: 'include',
  })

  if (!response.ok) {
    throw new Error('Failed to update reservation')
  }

  return response.json()
}

const AdminContext = createContext()

export const AdminProvider = ({ children }) => {
  const fetchPropertiesData = useQuery('properties', fetchRentalProperties, {
    cacheTime: 300000,
    refetchOnWindowFocus: false
  })
  const propertiesData = useMemo(
    () => ({
      properties: fetchPropertiesData.data,
      isLoading: fetchPropertiesData.isLoading,
      isError: fetchPropertiesData.isError,
      refetch: fetchPropertiesData.refetch
    }),
    [
      fetchPropertiesData.data,
      fetchPropertiesData.isLoading,
      fetchPropertiesData.isError,
      fetchPropertiesData.refetch
    ]
  )

  const fetchYearsWithBookingsData = useQuery('years', fetchYearsWithBookings, {
    cacheTime: 36000,
    refetchOnWindowFocus: false
  })

  const availableYears = useMemo(() => {
    if (!fetchYearsWithBookingsData.data) {
      return [new Date().getFullYear()]
    }

    return fillMissingYears(fetchYearsWithBookingsData.data)
  }, [fetchYearsWithBookingsData.data])

  const fetchReservationsData = useQuery('reservations', fetchReservations, {
    refetchOnWindowFocus: false,
    cacheTime: 36000
  })
  const createReservationMutation = useMutation(createReservation, {
    onSuccess: () => {
      fetchReservationsData.refetch()
    }
  })

  const updateReservationMutation = useMutation(updateReservation, {
    onSuccess: () => {
      fetchReservationsData.refetch()
    }
  })

  const reservationsData = useMemo(
    () => ({
      reservations: normalizeReservationData(fetchReservationsData.data),
      addReservation: createReservationMutation.mutateAsync,
      updateReservation: updateReservationMutation.mutateAsync,
      isLoading: fetchReservationsData.isLoading,
      isError: fetchReservationsData.isError,
      refetch: fetchReservationsData.refetch
    }),
    [
      fetchReservationsData.data, 
      fetchReservationsData.isLoading, 
      fetchReservationsData.isError, 
      fetchReservationsData.refetch, 
      createReservationMutation.mutateAsync, 
      updateReservationMutation.mutateAsync
    ]
  )

  const initialState = {
    activeYear: new Date().getFullYear(),
    activeWeekNumber: null,
    activeNumberOfWeeks: 1,
    activeBookingId: null,
    activePropertyId: null
  };

  const globalReducer = (state, action) => {
    console.log('globalReducer', action.type, action.payload);
    switch (action.type) {
      case 'SET_ACTIVE_BOOKING_ID':
        return {
          ...state,
          activeBookingId: action.payload
        }
      case 'SET_ACTIVE_WEEK_NUMBER':
        return {
          ...state,
          activeWeekNumber: action.payload
        }
      case 'SET_ACTIVE_NUMBER_OF_WEEKS':
        return {
          ...state,
          activeNumberOfWeeks: action.payload
        }
      case 'SET_ACTIVE_PROPERTY_ID':
        return {
          ...state,
          activePropertyId: action.payload
        }
      case 'SET_ACTIVE_YEAR':
        return {
          ...state,
          activeYear: action.payload
        }
      case 'SET_ACTIVE_STATE':
        return {
          ...state,
          ...action.payload
        }
      default:
        return state
    }
  };

  const [state, dispatch] = useReducer(globalReducer, initialState);

  const setActiveWeekNumber = (weekNumber) => {
    dispatch({
      type: 'SET_ACTIVE_WEEK_NUMBER',
      payload: weekNumber
    })
  };

  const setActivePropertyId = (propertyId) => {
    dispatch({
      type: 'SET_ACTIVE_PROPERTY_ID',
      payload: propertyId
    })
  };

  const setActiveBookingId = (bookingId) => {
    dispatch({
      type: 'SET_ACTIVE_BOOKING_ID',
      payload: bookingId
    })
  };

  const setActiveYear = (year) => {
    dispatch({
      type: 'SET_ACTIVE_YEAR',
      payload: year
    })
  };

  const setActiveState = (activeState) => {
    dispatch({
      type: 'SET_ACTIVE_STATE',
      payload: activeState
    })
  };

  const setActiveNumberOfWeeks = (numberOfWeeks) => {
    dispatch({
      type: 'SET_ACTIVE_NUMBER_OF_WEEKS',
      payload: numberOfWeeks
    })
  };

  const context = useMemo(() => {
    return {
      ...state,
      availableYears,
      propertiesData,
      reservationsData,
      setActiveWeekNumber,
      setActiveNumberOfWeeks,
      setActivePropertyId,
      setActiveBookingId,
      setActiveYear,
      setActiveState
    }
  }, [
    state,
    availableYears,
    propertiesData,
    reservationsData
  ])

  // console.log('MAUL', JSON.stringify(availableYears, null, 2))

  return (
    <AdminContext.Provider value={context}>{children}</AdminContext.Provider>
  )
}

export const useAdminContext = () => useContext(AdminContext)
