function isWeekReserved(week, year, reservation) {
  if (year < reservation.startYear || year > reservation.endYear) {
      return false;
  }
  if (year === reservation.startYear && week < reservation.startWeek) {
      return false;
  }
  if (year === reservation.endYear && week > reservation.endWeek) {
      return false;
  }
  return true;
}

exports.getAvailableWeeks = ({
  reservations, 
  weekNumber, 
  year, 
  maxWeeks = 4
}) => {
  // Helper function to check if a given week and year are within a reservation period

  let availableWeeks = 0;

  // Check each week starting from the given weekNumber up to maxWeeks
  for (let i = 0; i < maxWeeks; i++) {
      const currentWeek = weekNumber + i;
      let isReserved = false;

      // Check if the current week is reserved
      for (let reservation of reservations) {
          if (isWeekReserved(currentWeek, year, reservation)) {
              isReserved = true;
              break;
          }
      }

      // If the week is not reserved, count it as available
      if (!isReserved) {
          availableWeeks++;
      } else {
          break;
      }
  }

  if (availableWeeks === 0) {
      availableWeeks = 1;
  }

  return availableWeeks;
}