export const normalizeReservationData = (bookings = []) => {
    const groupedBookings = {};

    const defaultValueMap = {
        'original_price': 0,
        'custom_price': 0,
    };

    bookings.forEach(booking => {
        // Rename keys to camelCase
        const camelCaseBooking = {};
        for (let key in booking) {
            const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

            let value;
            if (booking[key] === null) {
                value = defaultValueMap[key] !== undefined ? defaultValueMap[key] : '';
            } else {
                value = booking[key];
            }

            camelCaseBooking[camelCaseKey] = value;
        }

        const { rentalPropertyId } = camelCaseBooking;

        if (!(rentalPropertyId in groupedBookings)) {
            groupedBookings[rentalPropertyId] = [];
        }

        groupedBookings[rentalPropertyId].push(camelCaseBooking);
    });

    return groupedBookings;
}
