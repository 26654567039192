import { startOfWeek, addDays, subDays, setISOWeek, setYear } from 'date-fns';

export const getSelectedDateFromSelectedWeek = (year, isoWeek) => {
  // Ange året och veckonumret
  let date = setISOWeek(setYear(new Date(), year), isoWeek);

  // Få starten av veckan (måndag enligt ISO-veckonummer)
  let monday = startOfWeek(date, { weekStartsOn: 1 });

  // Subtrahera en dag från måndagen för att få söndagen
  let sunday = subDays(monday, 1);

  return sunday;
}

export const getFutureDateFromFutureWeek = (year, isoWeek, futureIsoWeek) => {
  // Bestäm vilket år som ska användas baserat på de givna veckonumren
  let targetYear = isoWeek <= futureIsoWeek ? year : year + 1;

  // Ange året och det framtida veckonumret
  let date = setISOWeek(setYear(new Date(), targetYear), futureIsoWeek);

  // Få starten av veckan (måndag enligt ISO-veckonummer)
  let monday = startOfWeek(date, { weekStartsOn: 1 });

  // Subtrahera en dag från måndagen för att få söndagen
  let sunday = addDays(monday, 6);

  return sunday;
}

export function fillMissingYears(years = []) {
  // Sort the input array
  years.sort((a, b) => a - b);
  
  // Get the minimum and maximum year
  const minYear = years[0];
  const maxYear = years[years.length - 1] + 1;
  
  // Create an array to hold the complete range of years
  const completeYears = [];
  
  // Loop from the minimum year to the maximum year and add each year to the completeYears array
  for (let year = minYear; year <= maxYear; year++) {
    completeYears.push(year);
  }
  
  return completeYears;
}
